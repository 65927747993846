<template>
    <PageSlideTransition :direction="transitionDirection">
        <div v-if="currentPage === 'intro'" class="z-10 bg-white">
            <ProductFinderIntro />
        </div>

        <div v-else-if="currentPage === 'steps'" class="z-20 bg-white">
            <ProductFinderSteps :steps="steps" :selections="selections" />
        </div>

        <div v-else-if="currentPage === 'results'" class="z-30 bg-white">
            <ProductFinderResults :selections="selections" />
        </div>
    </PageSlideTransition>
</template>

<script setup lang="ts">
import { ProductFinderStep } from '~/types/ProductFinder';
import ProductFinderSteps from '~/components/product-finder/components/ProductFinderSteps.vue';
import PageSlideTransition from '~/components/transitions/PageSlideTransition.vue';
import ProductFinderIntro from '~/components/product-finder/components/ProductFinderIntro.vue';
import ProductFinderResults from '~/components/product-finder/components/ProductFinderResults.vue';
import { sendDataLayerQuizEvent } from '~/composables/useDataLayer';

const props = defineProps<{
    hash: string;
    steps: ProductFinderStep[];
}>();

const currentPage = ref<'intro' | 'steps' | 'results'>('intro');
const transitionDirection = ref<'forward' | 'backward'>('forward');

const selections = computed(() => {
    const allParams = new URLSearchParams(props.hash.replace('#', ''));
    const safeParams = new URLSearchParams();

    props.steps.forEach((step) => {
        if (allParams.has(step.key)) {
            if (allParams.get(step.key) === 'skip') {
                safeParams.set(step.key, 'skip');
            } else {
                const foundChoice = step.choices.find(
                    (choice) => choice.key === allParams.get(step.key),
                );

                if (foundChoice) {
                    safeParams.set(step.key, foundChoice.key);
                }
            }
        }
    });

    return safeParams;
});

watch(currentPage, (newPage, oldPage) => {
    if (oldPage === 'results' || (oldPage === 'steps' && newPage === 'intro')) {
        transitionDirection.value = 'backward';
    } else {
        transitionDirection.value = 'forward';
    }

    if (oldPage === 'intro' && newPage === 'steps') {
        sendDataLayerQuizEvent('quiz_started', '', '', '', '', '', '', '', '');
    }
});

watch(
    () => props.hash,
    (newHash) => {
        if (!newHash) {
            return;
        }

        if (selections.value.size === props.steps.length) {
            currentPage.value = 'results';
        } else if (/^#steps/.test(newHash)) {
            currentPage.value = 'steps';
        } else {
            currentPage.value = 'intro';
        }
    },
    { immediate: true },
);
</script>
